import React from 'react';
import { IntlProvider } from 'react-intl';
import { LazyMotion, domAnimation } from 'framer-motion';

import French from './compiled-lang/fr.json';
import English from './compiled-lang/en.json';

export default function WrapPageElement({ element, props }) {
  const { langKey } = props.pageContext;

  const messages = 'en' === langKey ? English : French;

  return (
    <LazyMotion features={domAnimation}>
      <IntlProvider locale={langKey} defaultLocale="fr" messages={messages}>
        {element}
      </IntlProvider>
    </LazyMotion>
  );
}
