exports.components = {
  "component---src-pages-404-en-jsx": () => import("./../../../src/pages/404.en.jsx" /* webpackChunkName: "component---src-pages-404-en-jsx" */),
  "component---src-pages-404-fr-jsx": () => import("./../../../src/pages/404.fr.jsx" /* webpackChunkName: "component---src-pages-404-fr-jsx" */),
  "component---src-pages-a-propos-fr-jsx": () => import("./../../../src/pages/a-propos.fr.jsx" /* webpackChunkName: "component---src-pages-a-propos-fr-jsx" */),
  "component---src-pages-about-en-jsx": () => import("./../../../src/pages/about.en.jsx" /* webpackChunkName: "component---src-pages-about-en-jsx" */),
  "component---src-pages-contact-en-jsx": () => import("./../../../src/pages/contact.en.jsx" /* webpackChunkName: "component---src-pages-contact-en-jsx" */),
  "component---src-pages-contact-fr-jsx": () => import("./../../../src/pages/contact.fr.jsx" /* webpackChunkName: "component---src-pages-contact-fr-jsx" */),
  "component---src-pages-creations-en-jsx": () => import("./../../../src/pages/creations.en.jsx" /* webpackChunkName: "component---src-pages-creations-en-jsx" */),
  "component---src-pages-custom-made-guitars-en-jsx": () => import("./../../../src/pages/custom-made-guitars.en.jsx" /* webpackChunkName: "component---src-pages-custom-made-guitars-en-jsx" */),
  "component---src-pages-guitares-personnalisees-fr-jsx": () => import("./../../../src/pages/guitares-personnalisees.fr.jsx" /* webpackChunkName: "component---src-pages-guitares-personnalisees-fr-jsx" */),
  "component---src-pages-guitares-pretes-a-jouer-fr-jsx": () => import("./../../../src/pages/guitares-pretes-a-jouer.fr.jsx" /* webpackChunkName: "component---src-pages-guitares-pretes-a-jouer-fr-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../../../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-fr-jsx": () => import("./../../../src/pages/index.fr.jsx" /* webpackChunkName: "component---src-pages-index-fr-jsx" */),
  "component---src-pages-livraison-et-retours-fr-jsx": () => import("./../../../src/pages/livraison-et-retours.fr.jsx" /* webpackChunkName: "component---src-pages-livraison-et-retours-fr-jsx" */),
  "component---src-pages-ready-to-play-guitars-en-jsx": () => import("./../../../src/pages/ready-to-play-guitars.en.jsx" /* webpackChunkName: "component---src-pages-ready-to-play-guitars-en-jsx" */),
  "component---src-pages-realisations-fr-jsx": () => import("./../../../src/pages/realisations.fr.jsx" /* webpackChunkName: "component---src-pages-realisations-fr-jsx" */),
  "component---src-pages-shipping-and-returns-en-jsx": () => import("./../../../src/pages/shipping-and-returns.en.jsx" /* webpackChunkName: "component---src-pages-shipping-and-returns-en-jsx" */),
  "component---src-pages-soft-goods-and-accessories-en-jsx": () => import("./../../../src/pages/soft-goods-and-accessories.en.jsx" /* webpackChunkName: "component---src-pages-soft-goods-and-accessories-en-jsx" */),
  "component---src-pages-temoignages-fr-jsx": () => import("./../../../src/pages/temoignages.fr.jsx" /* webpackChunkName: "component---src-pages-temoignages-fr-jsx" */),
  "component---src-pages-testimonials-en-jsx": () => import("./../../../src/pages/testimonials.en.jsx" /* webpackChunkName: "component---src-pages-testimonials-en-jsx" */),
  "component---src-pages-textiles-et-accessoires-fr-jsx": () => import("./../../../src/pages/textiles-et-accessoires.fr.jsx" /* webpackChunkName: "component---src-pages-textiles-et-accessoires-fr-jsx" */),
  "component---src-templates-custom-guitar-jsx": () => import("./../../../src/templates/CustomGuitar.jsx" /* webpackChunkName: "component---src-templates-custom-guitar-jsx" */),
  "component---src-templates-ready-to-play-guitar-jsx": () => import("./../../../src/templates/ReadyToPlayGuitar.jsx" /* webpackChunkName: "component---src-templates-ready-to-play-guitar-jsx" */),
  "component---src-templates-soft-good-accessory-index-jsx": () => import("./../../../src/templates/SoftGoodAccessory/index.jsx" /* webpackChunkName: "component---src-templates-soft-good-accessory-index-jsx" */)
}

